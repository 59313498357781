<template>
	<div>
		<ContentHeader title="Laporan Akuntansi" subTitle="Laporan Perubahan Modal" url="laporan-akuntansi" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
          <div class="card-header">
            <h3 class="card-title">Data Laporan</h3>
          </div>
          <div class="card-body" style="display: block;">
            <div class="row align-items-center mt-2">
              <div class="col-lg-3">
                <span>Periode Bulan</span>
                <input type="month" class="form-control" v-model="periode_bulan" @change="getDataResult">
              </div>
              <div class="col-lg-4"></div>
              <div class="col-lg-5" v-if="toogleCabang">
                <label>Pilih Cabang</label>
                <multiselect 
                  v-model="cabang_select_value"
                  deselect-label="Can't remove this value"
                  track-by="nama"
                  label="nama"
                  placeholder=""
                  :options="cabang"
                  :loading="loading_cabang"
                  :allow-empty="false"
                  @update:model-value="getDataResult"
                >
                </multiselect>
              </div>
            </div>
            <table class="table table-bordered table-hover mt-4">
              <thead>
                <tr>
                  <th scope="col" class="text-sm">NAMA KEUANGAN</th>
                  <th scope="col" class="text-sm" colspan="2">NOMINAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in data_result" :key="row.id">
                  <td class="text-sm p-2" scope="row">{{ row.nama }}</td>
                  <td class="text-sm text-right p-2" scope="row" colspan="2">{{ format_nominal(row.nominal) }}</td>
                </tr>
                <tr v-if="!memuat_data">
                  <td class="text-sm p-2" scope="row">MODAL AKHIR</td>
                  <td class="text-sm text-right p-2" scope="row" colspan="2">{{ format_nominal(modal_akhir) }}</td>
                </tr>
              </tbody>
            </table>
            <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
            <p class="text-center mt-2">{{ cek_data }}</p>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
          </div>
          <!-- /.card-footer-->
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'
import format_nominal from '@/format_nominal'

export default{
  components: {
    ContentHeader,
    Multiselect
  },
  setup(){
    const user = computed(() => store.getters['auth/user'])
    const data_result = ref([])
    const cek_data = ref('')
    const memuat_data = ref(false)
    const modal_akhir = ref(0)
    const d = new Date()
    let year = d.getFullYear()
    let month = d.getMonth() + 1
    const periode_bulan = ref(`${year}-${month}`)
    
    const getDataResult = async () => {
      data_result.value = []
      memuat_data.value = true
      cek_data.value = ''
      let bulan = ''

      if (periode_bulan.value == '') {
        bulan = 'emptyParameter'
      } else {
        bulan = periode_bulan.value
      }

      let cabang_id = ''

      if (cabang_select_value.value == '') {
        cabang_id = user.value.cabang_id
      } else {
        cabang_id = cabang_select_value.value.id
      }

      let { data } = await axios.get(`api/laporan/perubahanModal/${cabang_id}/${bulan}`)
      // console.log(data)
      if (data == 'kosong') {
        cek_data.value = 'Data Masih Kosong'
        memuat_data.value = false
        data_result.value = []
        modal_akhir.value = 0
      } else {
        memuat_data.value = false
        cek_data.value = ''
        data_result.value = data.data
        modal_akhir.value = data.modal_akhir
      }
    }

    const toogleCabang = ref(false)
    const cabang_select_value = ref('')
    const cabang = ref([])
    const loading_cabang = ref(true)
    const getCabang = async () => {
      let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

      if (response.data.jenis == 'pusat') {
        toogleCabang.value = true
        let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
        
        if (data != 'kosong') {
          cabang.value = data
          loading_cabang.value = false
        } else {
          cabang.value = []
          loading_cabang.value = false
        }
      }
    }


    onMounted(() => {
      getCabang()
      getDataResult()
    })

    return {
      data_result, cek_data, memuat_data, getDataResult, format_nominal, modal_akhir, periode_bulan, cabang_select_value, cabang, loading_cabang, toogleCabang
    }
  }
}
</script>